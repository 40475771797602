<template>
    <b-card v-if="grower">
  
      <b-row>
  
        <!-- User Info: Left col -->
        <b-col
          cols="21"
          xl="6"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-img
                thumbnail
                rounded="circle"
                width="104px"
                height="104px"
                :src="getPhoto()"
                style="cursor:pointer;"
                :title="grower.photo"
                @click="browse()"
            />
            <input
                id="url"
                name="url"
                ref="url"
                type="file"
                @change="previewImage($event)"
                style="display:none;"
            />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ grower.person.firstName }} {{ grower.person.middleName }} {{ grower.person.lastName }}
                </h4>
                <h4 class="mb-0" v-if="!grower">John Doe</h4>
                <span class="card-text">Phone: +255 712 738 338</span>
              </div>
              <div class="d-flex flex-wrap">
                <b-button
                  v-if="grower"
                  :to="{ name: 'apps-users-edit', params: { id: grower.id } }"
                  variant="primary"
                >
                  Edit
                </b-button>
                <b-button
                  variant="outline-danger"
                  class="ml-1"
                >
                  Delete
                </b-button>
              </div>
            </div>
          </div>
  
          <!-- User Stats -->
          <div class="d-flex align-items-center mt-2">
            <div class="d-flex align-items-center mr-2">
              <b-avatar
                variant="light-primary"
                rounded
              >
                <feather-icon
                  icon="DollarSignIcon"
                  size="18"
                />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">
                  23.3k
                </h5>
                <small>Monthly Sales</small>
              </div>
            </div>
  
            <div class="d-flex align-items-center">
              <b-avatar
                variant="light-success"
                rounded
              >
                <feather-icon
                  icon="TrendingUpIcon"
                  size="18"
                />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">
                  $99.87k
                </h5>
                <small>Annual Profit</small>
              </div>
            </div>
          </div>
        </b-col>
  
        <!-- Right Col: Table -->
        <b-col
          cols="12"
          xl="6"
        >
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Username</span>
              </th>
              <td class="pb-50">
                username@test.dev
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CheckIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Status</span>
              </th>
              <td class="pb-50 text-capitalize">
                Active
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Role</span>
              </th>
              <td class="pb-50 text-capitalize">
                Grower
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="FlagIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Country</span>
              </th>
              <td class="pb-50">
                Tanzania
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon
                  icon="PhoneIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Contact</span>
              </th>
              <td>
                grower@gmail.com
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
      <hr />
      
      <b-tabs
        align="left"
      >
        <b-tab
            v-for="(menu, index) in innerMenus"
            :key="menu.id"
            :active="index === 0"
            lazy
        >
            <template #title>
                <feather-icon v-if="menu.webIcon" :icon="menu.webIcon" />
                <span>{{ menu.title }}</span>
            </template>
            <div
                style="height:35vh;border:2px solid #eee;border-style:solid;"
            >
                <grower-contracts-tab :grower-id="grower.id" v-if="menu.webRoute === 'contracts'">ssss</grower-contracts-tab>
                <!-- <span v-if="menu.webRoute !== 'contracts' || menu.webRoute !== 'grower_loans'">{{ menu.title }}</span> -->

                <grower-loan-tab :grower-id="grower.id" v-if="menu.webRoute === 'grower_loans'"></grower-loan-tab>
            </div>
        </b-tab>
      </b-tabs>
    </b-card>
</template>
  
<script>
  import {
    BCard, BButton, BFormInput, BAvatar, BRow, BCol,
    BImg,
    BTabs, BTab, BCardText,
  } from 'bootstrap-vue'
  import GrowerContractsTab from './GrowerContractsTab.vue'
  import GrowerLoanTab from './GrowerLoanTab.vue'

  import { ref, onUnmounted, onMounted, getCurrentInstance } from '@vue/composition-api'
  import store from '@/store'
  import axios from '@axios'
  import { avatarText } from '@core/utils/filter'
  import growersStoreModule from '@/views/cromis/grower/growersStoreModule'
  
    export default {
        components: {
            BCard, BButton, BFormInput, BRow, BCol, BAvatar,
            BImg,
            BTabs, BTab, BCardText,

            // Local Components
            GrowerContractsTab,
            GrowerLoanTab,
        },
        props: {
            growerId: {
                type: Number,
                required: true,
            },
        },
        setup(props, context) {
            const grower = ref(null)
            const innerMenus = ref([])

            const apiPath = ref(axios.defaults.baseURL.substring(0, axios.defaults.baseURL.indexOf('/api')))

            const imgURL = ref(null)
            const url = ref(null)

            //   const { resolveUserRoleVariant } = useUsersList()

            const CROMIS_GROWER_STORE_MODULE_NAME = 'cromis-grower'

            // Register module
            if (!store.hasModule(CROMIS_GROWER_STORE_MODULE_NAME)) store.registerModule(CROMIS_GROWER_STORE_MODULE_NAME, growersStoreModule)

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(CROMIS_GROWER_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_GROWER_STORE_MODULE_NAME)
            })

            onMounted(async () => {
                await store.dispatch('cromis-grower/show', { id: props.growerId })
                    .then(response => {
                        grower.value = response.data
                        console.log(grower.value)
                    })
                    .catch(error => {
                        console.log(error)
                    })

                getInnerMenus(JSON.parse(localStorage.getItem('userData')).menus, context.root.$router.currentRoute.name)
            })

            const previewImage = (e) => {
                let file = e.target.files[0];
                let reader = new FileReader();

                reader.onloadend = (file) => {
                    imgURL.value = reader.result;
                }

                reader.readAsDataURL(file);
            }

            const getPhoto = () => {
                let photo = (imgURL.value && imgURL.value.length > 255) ? imgURL.value : apiPath.value + '/storage/' + grower.value.photo;
                return photo;
            }

            const browse = () => {
                url.value.click()
            }

            const getInnerMenus = (menus, currentMenu) => {
                menus.map((menu) => {
                    if(menu.children.length > 0)
                        getInnerMenus(menu.children, currentMenu)

                    if(menu.webRoute && menu.webRoute.localeCompare(currentMenu) === 0)
                        innerMenus.value = menu.children

                })
            }

            return {
                grower,
                apiPath,
                url,
                innerMenus,

                avatarText,
                previewImage,
                getPhoto,
                browse,
                // resolveUserRoleVariant,
            }
        },
    }
</script>
  
<style>
  
</style>
  