<template>
    <div>

        <!-- Form Modal -->
        <b-modal id="modal-season-form" ref="myModal" centered no-close-on-backdrop no-close-on-esc size="lg" @ok="submit">
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5 v-if="loan.id === null">Register Box</h5>
                <h5 v-if="loan.id !== null">Edit Box</h5>

                <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <b-button size="md" variant="primary" @click="ok()" :disabled="saving">
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                    <span v-if="!saving">Save</span>
                    <span v-if="saving">Saving...</span>
                </b-button>
                <b-button size="md" @click="cancel()" variant="outline-secondary">
                    Cancel
                </b-button>


            </template>

            <!-- Data Form in Modal Body -->
            <template #default="{ }">

                <b-row>
                    <b-col cols="12">
                        <!-- <b-alert show variant="danger" v-if="errosOcurs" class="p-1">{{ errosOcurs.message }}</b-alert> -->

                        <b-alert show variant="danger" v-if="errosOcurs">
                            <h4 class="alert-heading">
                                {{ errosOcurs.message }}
                            </h4>
                            <div class="alert-body">
                                <div v-for="(errosOcurs, field) in errosOcurs.errors" :key="field">
                                    <strong>{{ field }}:</strong>
                                    <ul>
                                        <li v-for="message in errosOcurs" :key="message">{{ message }}</li>
                                    </ul>
                                </div>
                            </div>
                        </b-alert>


                    </b-col>
                </b-row>
                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="handleCreate">

                        <b-row>
                            <b-col cols="12">
                                <b-form-group labe-for="Package Type" label="Package Type">
                                    <validation-provider #default="{ errors }" name="package_id" rules="">
                                        <b-form-select size="md" v-model="loan.package_id">
                                            <b-form-select-option :value="null">-- Select --</b-form-select-option>
                                            <b-form-select-option v-for="warehouse in packages" :key="warehouse.id"
                                                :value="warehouse.id">
                                                {{ warehouse.name }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small v-if="serverErrors && serverErrors.source_id" class="text-danger">{{
                                            serverErrors.source_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>

                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12">
                                <b-button size="sm" class="add-owner mb-1 pull-right" variant="outline-primary"
                                    @click="addElement()">Add
                                    Inputs</b-button>

                            </b-col>
                            <table class="table">
                                <thead>
                                    <tr class="text-center">
                                        <th>Input</th>
                                        <th width="13%">Quantity</th>
                                        <th>Is Topup?</th>
                                        <th>Date Taken</th>
                                        <th width="3%"> </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr v-for="element, index in  loan.inputs" :key="index">
                                        <td scope="row">
                                            <div>

                                                <validation-provider #default="{ errors }" name="package_id" rules="">
                                                    <b-form-select size="md" v-model="element.id">
                                                        <b-form-select-option :value="null">-- Select
                                                            --</b-form-select-option>
                                                        <b-form-select-option v-for="input in inputs" :key="input.id"
                                                            :value="input.id">
                                                            {{ input.name }}
                                                        </b-form-select-option>
                                                    </b-form-select>
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                    <small v-if="serverErrors && serverErrors.source_id"
                                                        class="text-danger">{{
                                                            serverErrors.source_id[0] }}</small>
                                                </validation-provider>

                                            </div>
                                        </td>
                                        <td scope="row">
                                            <div>

                                                <b-form-input id="share" name="start" v-model="element.quantity"
                                                    size="md" />

                                            </div>
                                        </td>
                                        <td scope="row">
                                            <div>

                                                <b-form-checkbox :checked="element.topup" class="custom-control-success"
                                                    name="check-button" switch v-model="element.topup">
                                                    <span class="switch-icon-left">
                                                        <feather-icon icon="CheckCircleIcon" />
                                                    </span>
                                                </b-form-checkbox>

                                            </div>
                                        </td>

                                        <td>
                                            <div>

                                                <!-- <validation-provider #default="{ errors }" name="dateTaken" rules="">
                                                    <b-form-datepicker id="dateTaken" :min="minDate"
                                                        name="dateTaken" v-model="element.dateTaken"
                                                        :state="errors.length > 0 ? false : null" size="md"
                                                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                        locale="en" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                    <small class="text-danger"
                                                        v-if="serverErrors && serverErrors.dateTaken">{{
                                                            serverErrors.dateTaken[0] }}</small>
                                                </validation-provider> -->

                                                <b-form-input v-model="element.dateTaken" type="date" size="md" />

                                            </div>

                                        </td>

                                        <td>
                                            <b-button variant="outline-danger" size="sm" @click="removeRow(index)">
                                                <feather-icon icon="TrashIcon" size="11"
                                                    class="text-danger stroke-current" />
                                            </b-button>
                                        </td>
                                        <!-- {{ element }} -->
                                    </tr>
                                    <tr>
                                        <td scope="row"></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </b-row>

                        <!--<pre>
                            {{ loan }}
                        </pre>-->
                    </b-form>
                </validation-observer>
            </template>




        </b-modal>
        <!-- ./Form Modal -->
        <!-- Table Container Card -->
        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">

                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">

                            <b-button variant="primary" size="sm" @click="invokeCreateForm()">
                                <span class="text-nowrap">Add Loan</span>
                            </b-button>
                        </div>
                    </b-col>
                    <!-- ./Search & Button-->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">SN</th>
                        <th scope="col">INPUT NAME</th>
                        <th scope="col">QUANTITY</th>
                        <th scope="col">APPLIED</th>
                        <th scope="col">AMOUNT</th>
                        <th scope="col">ACTION</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(loan, index)  in loans.inputs">
                        <th scope="row">{{ index + 1 }}</th>
                        <td>{{ loan.name }}</td>
                        <td>{{ loan.quantity }}</td>
                        <td>{{ loan.applied }}</td>
                        <td>{{ loan.amount }}</td>
                        <td></td>
                    </tr>

                </tbody>

              

            </table>
            <!-- Table Footer -->


        </b-card>
        <!-- ./Table Container Card -->
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BModal, VBModal, BButton, BTable,
    BBadge, BDropdown, BDropdownItem, BPagination, BSpinner, BMedia, BAvatar, BLink,
    BForm, BFormGroup, BFormSelect, BFormSelectOption, BFormInput,
    BFormCheckbox,
    BCard, BCardHeader, BCardSubTitle, BCardBody, BFormDatepicker,
} from 'bootstrap-vue'
import { required, integer } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import userGrowerLoanList from '@/views/cromis/grower/userGrowerLoanList'
import growersStoreModule from '@/views/cromis/grower/growersStoreModule'
import { useToast } from 'vue-toastification/composition'
import moment from 'moment'


export default {
    props: {
        growerId: {
            type: Number,
            required: true,
        },
    },

    components: {
        BCard,
        BCardHeader,
        BCardSubTitle,
        BCardBody,
        BRow,
        BCol,
        BForm,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BFormSelectOption,
        BFormCheckbox,
        BFormDatepicker,
        BModal,
        BButton,
        BSpinner,
        BMedia,
        BLink,
        BAvatar,
        BTable,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,

        vSelect,
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        'b-modal': VBModal,
    },
    setup(props, context) {
        const saving = ref(false)
        const serverErrors = ref(null)
        const myModal = ref(null)
        const packages = ref([])
        const inputs = ref([])
        const loans = ref([])
        const season_id = ref(0)
        const errosOcurs = ref(null)
        const loan = ref({
            id: null,
            package_id: null,
            season_id: null,
            debtor_type: 1,
            type: null,
            debtor_id: null,
            inputs: [
                {
                    id: null,
                    topup: false,
                    quantity: null,
                    dateTaken: null,
                }
            ]
        })
        const CROMIS_GROWER_STORE_MODULE_NAME = 'cromis-grower'

        // Register module
        if (!store.hasModule(CROMIS_GROWER_STORE_MODULE_NAME)) store.registerModule(CROMIS_GROWER_STORE_MODULE_NAME, growersStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_GROWER_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_GROWER_STORE_MODULE_NAME)


        })

        onMounted(async () => {
            await store.dispatch('cromis-grower/packages')
                .then(response => {
                    // console.log(response)
                    packages.value = response.data.packages
                })
                .catch(error => {
                    console.log('got error', error)
                })

            await store.dispatch('cromis-grower/loans', {
                debtor_id: grower_id.value,
                debtor_type: 1,
            }).then(response => {
                // console.log(response)
                loans.value = response.data.loans[0]
                // console.log(loans.value)
            })
                .catch(error => {
                    console.log('got error', error)
                })

            await store.dispatch('cromis-grower/inputs')
                .then(response => {
                    console.log(response)
                    inputs.value = response.data.inputs
                })
                .catch(error => {
                    console.log('got error', error)
                })


            await store.dispatch('cromis-grower/seasons')
                .then(response => {
                    // console.log(response)
                    response.data.seasons.forEach(el => {
                        if (el.isRunning == true) {

                            loan.value.season_id = el.id
                            season_id.value = el.id
                            // console.log(loan.value)
                        }
                    });
                })
                .catch(error => {
                    console.log('got error', error)
                })
        })

        const {
           
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,

            // UI
            // resolveUserRoleVariant,
            // resolveUserRoleIcon,
            resolveGrowerTypeVariant,
            resolveStatusVariant,

            // Filers
            grower_id,
        } = userGrowerLoanList()

        grower_id.value = props.growerId

        // Methods
        const invokeUpdateForm = (item) => {
            society.value = item

            myModal.value.show()
        }

        const invokeCreateForm = () => {
            loan.value = {
                id: null,
                package_id: null,
                season_id: null,
                debtor_type: 1,
                debtor_id: grower_id.value,
                type: null,
                inputs: [
                    {
                        id: null,
                        topup: false,
                        quantity: null,
                        dateTaken: null,
                    }
                ]
            }

            myModal.value.show()
        }

        const isFormValid = async () => {
            let isValid = false
            await dataForm.value.validate().then(success => {
                isValid = success
            })

            return isValid
        }

        const submit = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            // Handle form submit
            if (loan.value.id === null || loan.value.id === 0)
                handleCreate()
            else
                handleUpdate(loan.value)
        }

        const handleCreate = async () => {
            console.log(loan.value)
            saving.value = true

            loan.value.season_id = season_id.value

            await store.dispatch('cromis-grower/loanSave', loan.value)
                .then(response => {
                    refetch()
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Grower ${response.data.person.firstName} ${response.data.person.lastName} has been Associated with Loan successfully!`,
                        showConfirmButton: true,
                        timer: 5000,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const getPackage = async () => {

        }

        const handleUpdate = async (item) => {
            saving.value = true
            console.log('updating...')
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }

            await store.dispatch('cromis-grower/update', item.id, item)
                .then(response => {
                    refetch()
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to grower ${response.data.person.firstName} ${response.data.person.lastName} has been saved successfully!`,
                        showConfirmButton: false,
                        timer: 5000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    context.root.$swal({
                        icon: 'error',
                        title: 'Server Error',
                        text: 'Something went wrong. See tech support',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-danger',
                        },
                        buttonsStyling: false,
                    })
                })
        }

        const addElement = () => {
            loan.value.inputs.push({ id: '', end: '', quantity: '', dateTaken: '' })

            if (loan.value.inputs.length > 0) {


                console.log(loan.value.inputs)
            }
        }

        const removeRow = (index) => {
            console.log(index)
            if (loan.value.inputs.length > 1) {
                loan.value.inputs.splice(index, 1)
                loan.value.inputs = loan.value.inputs
            }
        }

        const remove = async (id) => {
            await store.dispatch('cromis-grower/remove', id)
                .then(response => {
                    refetch()
                })
                .catch(error => {
                    context.root.$swal({
                        icon: 'error',
                        title: 'Server Error',
                        text: 'Something went wrong. See tech support',
                        showConfirmButton: false,
                        customClass: {
                            confirmButton: 'btn btn-danger',
                        },
                        buttonsStyling: false,
                    })
                })
        }

        return {
            fetch,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            myModal,
            addElement,
            removeRow,
            errosOcurs,
            // UI
            // resolveUserRoleVariant,
            // resolveUserRoleIcon,
            resolveGrowerTypeVariant,
            resolveStatusVariant,

            // Validations
            required,
            integer,

            // Methods and Form refs
            saving,
            serverErrors,
            invokeCreateForm,
            invokeUpdateForm,
            isFormValid,
            submit,
            handleCreate,
            handleUpdate,
            remove,
            packages,
            loan,
            getPackage,
            inputs,
            loans

        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>
