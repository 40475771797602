<template>
    <div>
        <template>
            <b-row>
                <b-col cols="12">
                    <grower-info-card :grower-id="parseInt(''+id)"></grower-info-card>
                </b-col>
            </b-row>
        </template>    
    </div>
</template>

<script>
    import {
        BRow, BCol, BAlert, BLink,
    } from 'bootstrap-vue'
    import GrowerInfoCard from './GrowerInfoCard.vue';
    export default {
        props: {},
        components: {
            BRow,
            BCol,
            BAlert,
            BLink,

            // Local components
            GrowerInfoCard,
        },
        directives: {},

        setup(props, context) {
            const { id } = context.root.$router.currentRoute.params

            return {
                id,
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>